<template>
  <div class="companyDetail-container">
    <a-drawer
      class="companyDetail-drawer"
      title="员工列表详情"
      placement="right"
      :closable="true"
      :visible="drawer"
      @close="handleClose"
    >
      <div
        v-loading="loading"
        element-loading-text="数据较多，拼命加载中..."
        class="drawer-container"
      >
        <div class="drawer-left">
          <div class="left-top">
            <el-form
              :model="editRuleForm"
              :rules="editRules"
              ref="editRuleForm"
              class="demo-ruleForm"
            >
              <div class="detail-title">
                <div class="tit-info">
                  <div class="shopName-custName">
                    用户名称：{{ companyInfo.nick_name || "--" }}
                  </div>
                </div>
              </div>
              <div class="detail-content">
                <div class="DisableEdit">
                  账号ID: {{ companyInfo.account || "--" }}
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'userDepartId'"
                  class="edit-input-container"
                  label="所属部门:"
                >
                  <a-cascader
                    class="edit-input"
                    :fieldNames="{
                      label: 'name',
                      value: 'id',
                      children: 'child'
                    }"
                    :allowClear="false"
                    v-model="editRuleForm.userDepartId"
                    :options="selectDepart"
                    placeholder="请选择"
                    change-on-select
                    @blur="
                      () => {
                        editCancelBtn('userDepartId');
                      }
                    "
                    @change="
                      (e) => {
                        editCompanyDetail('userDepartId');
                      }
                    "
                  />
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('userDepartId', companyInfo.user_depart_id);
                    }
                  "
                  v-if="editParams.filedName != 'userDepartId'"
                  class="hoverEdit"
                >
                  所属部门:
                  <span class="editColor">{{
                    companyInfo.user_depart_name || "--"
                  }}</span>
                </div>

                <div class="DisableEdit"></div>

                <el-form-item
                  v-if="editParams.filedName == 'sex'"
                  class="edit-input-container"
                  prop="sex"
                  label="性别:"
                >
                  <el-select
                    class="edit-input"
                    ref="sex"
                    v-model="editRuleForm.sex"
                    placeholder="请选择"
                    @blur="
                      () => {
                        editCancelBtn('sex');
                      }
                    "
                    @change="
                      (e) => {
                        editCompanyDetail('sex');
                      }
                    "
                    filterable
                  >
                    <el-option
                      v-for="(item, key) in sex"
                      :key="key"
                      :label="item"
                      :value="key"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('sex', companyInfo.sex);
                    }
                  "
                  v-if="editParams.filedName != 'sex'"
                  class="hoverEdit"
                >
                  性别:
                  <span class="editColor">{{
                    companyInfo.sex || companyInfo.sex == "0"
                      ? sex[companyInfo.sex]
                      : "--"
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'roleId'"
                  class="edit-input-container editInputContainer"
                  label="角色:"
                  prop="roleId"
                >
                  <el-select
                    class="edit-input"
                    ref="role_id"
                    v-model="editRuleForm.roleId"
                    placeholder="请选择"
                    @blur="
                      () => {
                        editCancelBtn('roleId');
                      }
                    "
                    @change="
                      (e) => {
                        editCompanyDetail('roleId');
                      }
                    "
                    filterable
                  >
                    <el-option
                      v-for="item in selectRole"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('roleId', companyInfo.role_id);
                    }
                  "
                  v-if="editParams.filedName != 'roleId'"
                  class="hoverEdit"
                >
                  角色:
                  <span class="editColor">{{
                    companyInfo.role_id || companyInfo.role_id == "0"
                      ? selectRole.filter((item) => {
                          return companyInfo.role_id == item.id;
                        })[0].name
                      : "--"
                  }}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'serviceLevelId' && companyInfo.role_id == 1" class="edit-input-container editInputContainer" label="客服等级:" prop="serviceLevelId">
                  <el-select class="edit-input" ref="serviceLevelId" v-model="editRuleForm.serviceLevelId" placeholder="请选择" @blur="() => {editCancelBtn('serviceLevelId')}" @change="(e) => {editCompanyDetail('serviceLevelId')}" filterable>
                    <el-option v-for="item in levelSelect" :key="item.id + ''" :label="item.serviceLevel" :value="item.id + ''"></el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('serviceLevelId', companyInfo.serviceLevelId)}" v-if="editParams.filedName != 'serviceLevelId' && companyInfo.role_id == 1" class="hoverEdit">
                  客服等级:
                  <span class="editColor">{{companyInfo.serviceLevelName || "--" }}</span>
                </div>

                <div v-if="companyInfo.role_id != 1" class="DisableEdit"></div>

                <el-form-item
                  v-if="editParams.filedName == 'birthDay'"
                  class="edit-input-container"
                  prop="birthDay"
                  label="生日:"
                >
                  <el-date-picker
                    ref="birth_day"
                    class="edit-input"
                    v-model="editRuleForm.birthDay"
                    type="date"
                    @blur="
                      () => {
                        editCancelBtn('birthDay');
                      }
                    "
                    @change="
                      (e) => {
                        editCompanyDetail('birthDay');
                      }
                    "
                    filterable
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('birthDay', companyInfo.birth_day);
                    }
                  "
                  v-if="editParams.filedName != 'birthDay'"
                  class="hoverEdit"
                >
                  生日:
                  <span class="editColor">{{
                    (companyInfo && companyInfo.birth_day) || "--"
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'addressId'"
                  class="edit-input-container"
                  prop="addressId"
                  label="省市区:"
                >
                  <el-cascader
                    ref="addressId"
                    class="edit-input"
                    placeholder="请选择"
                    v-model="editRuleForm.addressId"
                    @blur="
                      () => {
                        editCancelBtn('addressId');
                      }
                    "
                    @change="
                      (e) => {
                        editCompanyDetail('addressId');
                      }
                    "
                    :props="props"
                    filterable
                  ></el-cascader>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('addressId', companyInfo.addressId);
                    }
                  "
                  v-if="editParams.filedName != 'addressId'"
                  class="hoverEdit"
                >
                  省市区:
                  <span class="editColor">{{
                    (companyInfo && companyInfo.areaName) || "--"
                  }}</span>
                </div>
                <el-form-item
                  v-if="editParams.filedName == 'address'"
                  class="edit-input-container"
                  prop="address"
                  label="详细地址:"
                >
                  <el-input
                    ref="address"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.address"
                    @blur="
                      () => {
                        editCancelBtn('address');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editCompanyDetail('address');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('address', companyInfo.address);
                    }
                  "
                  v-if="editParams.filedName != 'address'"
                  class="hoverEdit"
                >
                  详细地址:
                  <span class="editColor">{{
                    (companyInfo && companyInfo.address) || "--"
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'bankCard'"
                  class="edit-input-container"
                  prop="bankCard"
                  label="银行卡号:"
                >
                  <el-input
                    ref="bank_card"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model.number="editRuleForm.bankCard"
                    @blur="
                      () => {
                        editCancelBtn('bankCard');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editCompanyDetail('bankCard');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('bankCard', companyInfo.bank_card);
                    }
                  "
                  v-if="editParams.filedName != 'bankCard'"
                  class="hoverEdit"
                >
                  银行卡号:
                  <span class="editColor">{{
                    (companyInfo && companyInfo.bank_card) || "--"
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'wage'"
                  class="edit-input-container"
                  prop="wage"
                  label="基本工资:"
                >
                  <el-input
                    ref="wage"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model.number="editRuleForm.wage"
                    @blur="
                      () => {
                        editCancelBtn('wage');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editCompanyDetail('wage');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('wage', companyInfo.wage);
                    }
                  "
                  v-if="editParams.filedName != 'wage'"
                  class="hoverEdit"
                >
                  基本工资:
                  <span class="editColor">{{
                    (companyInfo && companyInfo.wage) || "--"
                  }}</span>
                </div>

                <div class="DisableEdit">
                  入职时间: {{ companyInfo.join_time || "--" }}
                </div>
                <div class="DisableEdit">
                  创建时间: {{ companyInfo.create_time || "--" }}
                </div>
              </div>
            </el-form>
          </div>
          <el-tabs class="tabsDetail" v-model="activeTab">
            <el-tab-pane label="基本信息" name="1">
              <div class="data-content">
                <div class="detail-content">
                  <div class="DisableEdit">
                    修改人:
                    <span class="common-clr-0a6">{{
                      (companyInfo && companyInfo.update_nick_name) || "--"
                    }}</span>
                  </div>
                  <div class="DisableEdit">
                    修改人所属部门:
                    <span class="common-clr-0a6">{{
                      (companyInfo && companyInfo.update_user_depart_name) || "--"
                    }}</span>
                  </div>
                  <div class="DisableEdit">
                    最后修改时间:
                    <span class="common-clr-0a6">{{
                      (companyInfo && companyInfo.update_time) || "--"
                    }}</span>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs
            style="height: 100%;display: flex;flex-direction: column;"
            v-model="activeName"
          >
            <el-tab-pane label="工作记录" name="about">
              <common-work-log
                :followRecordList="followRecordList"
              ></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { basic, edit, listUpdate } from "../../../service/login.js";
import { sysArea, sysAreaDetail } from "../../../service/common.js";
import {
  tableColumn,
  DateTransform,
  decimalNull,
  Config
} from "../../../utils/index.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue";
import { Cascader, Drawer } from "ant-design-vue";
import Vue from "vue";
Vue.use(Drawer);

export default {
  props: ["drawer", "selectRole", "selectDepart", "levelSelect"],
  components: { CommonWorkLog, aCascader: Cascader, aDrawer: Drawer },
  data() {
    return {
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          resolve(nodes);
        }
      },
      sex: {
        "0": "女",
        "1": "男"
      },
      Config,
      tableColumn,
      DateTransform,
      tableRow: {}, // 表格数据
      companyInfo: {}, //员工列表详情
      loading: false,
      fileList: [],
      orderTable: [],
      paginationOrder: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      orderParams: {
        pageNum: 1,
        pageSize: 10
      },
      serTable: [],
      paginationSer: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      serParams: {
        pageNum: 1,
        pageSize: 10
      },
      followRecordList: [], // 工作日志
      filesList: [], // 工作记录文件
      isShowInput: "",
      editRuleForm: {
        //表单绑定form
        addressId: "",
        address: "",
        bankCard: "",
        wage: "",
        birthDay: "",
        roleId: '',
        serviceLevelId: ''
      },
      editRules: {
        // 校验规则
        agreeSubNum: [{ validator: decimalNull, trigger: "change" }]
      },
      editParams: {
        //修改的参数
        filedName: "",
        filedValue: ""
      },
      activeName: "about", // tabs
      activeTab: "1", // tabs
      recordContent: "", // 跟进记录内容
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ""
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
    };
  },
  methods: {
    async companyDetail(id) {
      // 公司详情
      this.loading = true;
      let { data } = await basic({ adminId: id });
      if (data.address_id) {
        let addressIdArr = data.address_id ? data.address_id.split(",") : [];
        data.areaName = (
          await sysAreaDetail({ id: addressIdArr[addressIdArr.length - 1] })
        ).data;
        data.addressId = addressIdArr;
      }

      this.loading = false;

      this.companyInfo = data;
      if (data.file_url && data.file_name) {
        let idArr = data.file_id.split(",");
        let nameArr = data.file_name.split(",");
        let urlArr = data.file_url.split(",");
        let fileList = [];
        urlArr.map((item, index) => {
          fileList.push({ url: item, name: nameArr[index], id: idArr[index] });
        });
        this.fileList = fileList;
      } else {
        this.fileList = [];
      }
    },
    getDataInfo(row) {
      // 父组件传默认值
      this.tableRow = row;
      this.companyDetail(row.id);
      this.workLog(row.id);
    },
    async editCompanyDetail(name) {
      // 确认-提交修改
      let params = {
        adminId: this.tableRow.id,
        type: name,
        value: this.editRuleForm[name],
        oldValue: this.companyInfo[name]
      };
      if (name == "serviceLevelId") {
        params.type = 'serviceLevel'
        params.oldValue = this.companyInfo.serviceLevelId || "";
      }
      if (name == "addressId") {
        params.value = params.value.join(",");
        params.oldValue = this.companyInfo.address_id || "";
      }
      if (name == "roleId") {
        params.oldValue = this.companyInfo.role_id || "";
      }
      if (name == "userDepartId") {
        params.oldValue = this.companyInfo.user_depart_id
          ? this.companyInfo.user_depart_id
          : (this.companyInfo.user_depart_id == "0" ? "0" : "") || "";
        if (params.value && params.value.length) {
          params.value = params.value[params.value.length - 1];
        } else {
          delete params.value;
        }
      }
      if (name == "sex") {
        params.oldValue = this.companyInfo.sex
          ? this.companyInfo.sex
          : this.companyInfo.sex == "0"
          ? "0"
          : "";
      }
      if (name == "birthDay") {
        params.oldValue = this.companyInfo.birth_day || "";
      }
      if (name == "bankCard") {
        params.oldValue = this.companyInfo.bank_card || "";
      }
      if (name == "wage") {
        params.oldValue = this.companyInfo.wage || "";
      }
      if (this.editParams.filedName) {
        if(name == 'userDepartId'){
            this.loading = true;
            await listUpdate(params);
            this.companyDetail(this.tableRow.id);
            this.workLog(this.tableRow.id);
            this.editParams = {
              filedName: "",
              filedValue: ""
            };
        }else{
          this.$refs["editRuleForm"].validateField(name, async (error) => {
          if (!error) {
            this.loading = true;
            await listUpdate(params);
            this.companyDetail(this.tableRow.id);
            this.workLog(this.tableRow.id);
            this.editParams = {
              filedName: "",
              filedValue: ""
            };
          } else {
            return false;
          }
        });
        }
      }
    },
    editCancelBtn(name) {
      // 取消编辑
      setTimeout(() => {
        if (this.isShowInput == name) {
          this.editParams = {
            filedName: "",
            filedValue: ""
          };
          this.isShowInput = "";
        }
      }, 500);
    },
    async workLog(id) {
      // 工作日志
      let resData = (await edit({ adminId: id })).data;
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = [];
          }
        });
      }
      this.followRecordList = resData;
    },
    editBtn(name, value) {
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          new Promise((resolve) => {
            this.editParams = {
              filedName: name,
              filedValue: value
            };
            if (name == "userDepartId") {
              this.findParent(value,this.selectDepart);
            } else {
              this.editRuleForm[name] = value;
            }
            resolve();
          })
            .then(() => {
              if (this.$refs[name]) {
                this.$refs[name].focus();
              }
            })
            .then(() => {
              this.isShowInput = name;
            });
        } else {
          return;
        }
      });
    },
    // 递归父id
    findParent(childrenId, arr, path) {
      if (path === undefined) {
        path = []
      }
      for (let i = 0; i < arr.length; i++) {
        let tmpPath = path.concat();
        tmpPath.push(arr[i].id);
        if (childrenId == arr[i].id) {
          return this.editRuleForm.userDepartId = tmpPath
        }
        if (arr[i].child) {
          let findResult = this.findParent(childrenId, arr[i].child, tmpPath);
          if (findResult) {
            return this.editRuleForm.userDepartId = findResult
            // return this.parentIds = findResult
          }
        }
      }
    },
    handleClose(done) {
      this.editRuleForm.userDepartId = []
      this.$emit("handleClose", done);
    }
  }
};
</script>
<style lang="less" scoped>
.companyDetail-drawer {
  /deep/ .ant-cascader-picker-label {
    width: 148px !important;
  }
  /deep/.ant-drawer-content-wrapper {
    width: 1656px !important;
  }
  /deep/ .ant-drawer-body {
    padding: 0 !important;
  }
  .create-company-conatiner {
    table {
      border: 1px solid #afafaf;
      box-sizing: border-box;
      td {
        border: 1px solid #afafaf;
        padding: 12px 16px;
      }
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .left-top {
        display: flex;
        justify-content: space-between;
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #ebeef5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #ebeef5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890ff;
            color: #1890ff;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;

        .shopName-custName {
          display: inline-block;
          display: block;
          color: #333;
          font-size: 18px;
        }
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #f98787;
          color: #f98787;
          margin-left: 16px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99%
            center #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
    }
  }
}
</style>
