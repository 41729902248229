var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"companyDetail-container"},[_c('a-drawer',{staticClass:"companyDetail-drawer",attrs:{"title":"员工列表详情","placement":"right","closable":true,"visible":_vm.drawer},on:{"close":_vm.handleClose}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"drawer-container",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticClass:"drawer-left"},[_c('div',{staticClass:"left-top"},[_c('el-form',{ref:"editRuleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.editRuleForm,"rules":_vm.editRules}},[_c('div',{staticClass:"detail-title"},[_c('div',{staticClass:"tit-info"},[_c('div',{staticClass:"shopName-custName"},[_vm._v(" 用户名称："+_vm._s(_vm.companyInfo.nick_name || "--")+" ")])])]),_c('div',{staticClass:"detail-content"},[_c('div',{staticClass:"DisableEdit"},[_vm._v(" 账号ID: "+_vm._s(_vm.companyInfo.account || "--")+" ")]),(_vm.editParams.filedName == 'userDepartId')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"label":"所属部门:"}},[_c('a-cascader',{staticClass:"edit-input",attrs:{"fieldNames":{
                    label: 'name',
                    value: 'id',
                    children: 'child'
                  },"allowClear":false,"options":_vm.selectDepart,"placeholder":"请选择","change-on-select":""},on:{"blur":function () {
                      _vm.editCancelBtn('userDepartId');
                    },"change":function (e) {
                      _vm.editCompanyDetail('userDepartId');
                    }},model:{value:(_vm.editRuleForm.userDepartId),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "userDepartId", $$v)},expression:"editRuleForm.userDepartId"}})],1):_vm._e(),(_vm.editParams.filedName != 'userDepartId')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('userDepartId', _vm.companyInfo.user_depart_id);
                  }}},[_vm._v(" 所属部门: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s(_vm.companyInfo.user_depart_name || "--"))])]):_vm._e(),_c('div',{staticClass:"DisableEdit"}),(_vm.editParams.filedName == 'sex')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"prop":"sex","label":"性别:"}},[_c('el-select',{ref:"sex",staticClass:"edit-input",attrs:{"placeholder":"请选择","filterable":""},on:{"blur":function () {
                      _vm.editCancelBtn('sex');
                    },"change":function (e) {
                      _vm.editCompanyDetail('sex');
                    }},model:{value:(_vm.editRuleForm.sex),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "sex", $$v)},expression:"editRuleForm.sex"}},_vm._l((_vm.sex),function(item,key){return _c('el-option',{key:key,attrs:{"label":item,"value":key}})}),1)],1):_vm._e(),(_vm.editParams.filedName != 'sex')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('sex', _vm.companyInfo.sex);
                  }}},[_vm._v(" 性别: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s(_vm.companyInfo.sex || _vm.companyInfo.sex == "0" ? _vm.sex[_vm.companyInfo.sex] : "--"))])]):_vm._e(),(_vm.editParams.filedName == 'roleId')?_c('el-form-item',{staticClass:"edit-input-container editInputContainer",attrs:{"label":"角色:","prop":"roleId"}},[_c('el-select',{ref:"role_id",staticClass:"edit-input",attrs:{"placeholder":"请选择","filterable":""},on:{"blur":function () {
                      _vm.editCancelBtn('roleId');
                    },"change":function (e) {
                      _vm.editCompanyDetail('roleId');
                    }},model:{value:(_vm.editRuleForm.roleId),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "roleId", $$v)},expression:"editRuleForm.roleId"}},_vm._l((_vm.selectRole),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),(_vm.editParams.filedName != 'roleId')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('roleId', _vm.companyInfo.role_id);
                  }}},[_vm._v(" 角色: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s(_vm.companyInfo.role_id || _vm.companyInfo.role_id == "0" ? _vm.selectRole.filter(function (item) { return _vm.companyInfo.role_id == item.id; })[0].name : "--"))])]):_vm._e(),(_vm.editParams.filedName == 'serviceLevelId' && _vm.companyInfo.role_id == 1)?_c('el-form-item',{staticClass:"edit-input-container editInputContainer",attrs:{"label":"客服等级:","prop":"serviceLevelId"}},[_c('el-select',{ref:"serviceLevelId",staticClass:"edit-input",attrs:{"placeholder":"请选择","filterable":""},on:{"blur":function () {_vm.editCancelBtn('serviceLevelId')},"change":function (e) {_vm.editCompanyDetail('serviceLevelId')}},model:{value:(_vm.editRuleForm.serviceLevelId),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "serviceLevelId", $$v)},expression:"editRuleForm.serviceLevelId"}},_vm._l((_vm.levelSelect),function(item){return _c('el-option',{key:item.id + '',attrs:{"label":item.serviceLevel,"value":item.id + ''}})}),1)],1):_vm._e(),(_vm.editParams.filedName != 'serviceLevelId' && _vm.companyInfo.role_id == 1)?_c('div',{staticClass:"hoverEdit",on:{"click":function () {_vm.editBtn('serviceLevelId', _vm.companyInfo.serviceLevelId)}}},[_vm._v(" 客服等级: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s(_vm.companyInfo.serviceLevelName || "--"))])]):_vm._e(),(_vm.companyInfo.role_id != 1)?_c('div',{staticClass:"DisableEdit"}):_vm._e(),(_vm.editParams.filedName == 'birthDay')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"prop":"birthDay","label":"生日:"}},[_c('el-date-picker',{ref:"birth_day",staticClass:"edit-input",attrs:{"type":"date","filterable":"","placeholder":"选择日期","format":"yyyy 年 MM 月 dd 日","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},on:{"blur":function () {
                      _vm.editCancelBtn('birthDay');
                    },"change":function (e) {
                      _vm.editCompanyDetail('birthDay');
                    }},model:{value:(_vm.editRuleForm.birthDay),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "birthDay", $$v)},expression:"editRuleForm.birthDay"}})],1):_vm._e(),(_vm.editParams.filedName != 'birthDay')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('birthDay', _vm.companyInfo.birth_day);
                  }}},[_vm._v(" 生日: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.birth_day) || "--"))])]):_vm._e(),(_vm.editParams.filedName == 'addressId')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"prop":"addressId","label":"省市区:"}},[_c('el-cascader',{ref:"addressId",staticClass:"edit-input",attrs:{"placeholder":"请选择","props":_vm.props,"filterable":""},on:{"blur":function () {
                      _vm.editCancelBtn('addressId');
                    },"change":function (e) {
                      _vm.editCompanyDetail('addressId');
                    }},model:{value:(_vm.editRuleForm.addressId),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "addressId", $$v)},expression:"editRuleForm.addressId"}})],1):_vm._e(),(_vm.editParams.filedName != 'addressId')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('addressId', _vm.companyInfo.addressId);
                  }}},[_vm._v(" 省市区: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.areaName) || "--"))])]):_vm._e(),(_vm.editParams.filedName == 'address')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"prop":"address","label":"详细地址:"}},[_c('el-input',{ref:"address",staticClass:"edit-input",attrs:{"placeholder":"请输入内容"},on:{"blur":function () {
                      _vm.editCancelBtn('address');
                    }},model:{value:(_vm.editRuleForm.address),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "address", $$v)},expression:"editRuleForm.address"}},[_c('template',{slot:"append"},[_c('div',{staticClass:"cancel",on:{"click":function () {
                          _vm.editCompanyDetail('address');
                        }}},[_vm._v(" 确认 ")])])],2)],1):_vm._e(),(_vm.editParams.filedName != 'address')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('address', _vm.companyInfo.address);
                  }}},[_vm._v(" 详细地址: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.address) || "--"))])]):_vm._e(),(_vm.editParams.filedName == 'bankCard')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"prop":"bankCard","label":"银行卡号:"}},[_c('el-input',{ref:"bank_card",staticClass:"edit-input",attrs:{"placeholder":"请输入内容"},on:{"blur":function () {
                      _vm.editCancelBtn('bankCard');
                    }},model:{value:(_vm.editRuleForm.bankCard),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "bankCard", _vm._n($$v))},expression:"editRuleForm.bankCard"}},[_c('template',{slot:"append"},[_c('div',{staticClass:"cancel",on:{"click":function () {
                          _vm.editCompanyDetail('bankCard');
                        }}},[_vm._v(" 确认 ")])])],2)],1):_vm._e(),(_vm.editParams.filedName != 'bankCard')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('bankCard', _vm.companyInfo.bank_card);
                  }}},[_vm._v(" 银行卡号: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.bank_card) || "--"))])]):_vm._e(),(_vm.editParams.filedName == 'wage')?_c('el-form-item',{staticClass:"edit-input-container",attrs:{"prop":"wage","label":"基本工资:"}},[_c('el-input',{ref:"wage",staticClass:"edit-input",attrs:{"placeholder":"请输入内容"},on:{"blur":function () {
                      _vm.editCancelBtn('wage');
                    }},model:{value:(_vm.editRuleForm.wage),callback:function ($$v) {_vm.$set(_vm.editRuleForm, "wage", _vm._n($$v))},expression:"editRuleForm.wage"}},[_c('template',{slot:"append"},[_c('div',{staticClass:"cancel",on:{"click":function () {
                          _vm.editCompanyDetail('wage');
                        }}},[_vm._v(" 确认 ")])])],2)],1):_vm._e(),(_vm.editParams.filedName != 'wage')?_c('div',{staticClass:"hoverEdit",on:{"click":function () {
                    _vm.editBtn('wage', _vm.companyInfo.wage);
                  }}},[_vm._v(" 基本工资: "),_c('span',{staticClass:"editColor"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.wage) || "--"))])]):_vm._e(),_c('div',{staticClass:"DisableEdit"},[_vm._v(" 入职时间: "+_vm._s(_vm.companyInfo.join_time || "--")+" ")]),_c('div',{staticClass:"DisableEdit"},[_vm._v(" 创建时间: "+_vm._s(_vm.companyInfo.create_time || "--")+" ")])],1)])],1),_c('el-tabs',{staticClass:"tabsDetail",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":"基本信息","name":"1"}},[_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"detail-content"},[_c('div',{staticClass:"DisableEdit"},[_vm._v(" 修改人: "),_c('span',{staticClass:"common-clr-0a6"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.update_nick_name) || "--"))])]),_c('div',{staticClass:"DisableEdit"},[_vm._v(" 修改人所属部门: "),_c('span',{staticClass:"common-clr-0a6"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.update_user_depart_name) || "--"))])]),_c('div',{staticClass:"DisableEdit"},[_vm._v(" 最后修改时间: "),_c('span',{staticClass:"common-clr-0a6"},[_vm._v(_vm._s((_vm.companyInfo && _vm.companyInfo.update_time) || "--"))])])])])])],1)],1),_c('div',{staticClass:"drawer-right"},[_c('el-tabs',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"工作记录","name":"about"}},[_c('common-work-log',{attrs:{"followRecordList":_vm.followRecordList}})],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }